import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';
import { useSelector } from 'src/redux/store';

// ----------------------------------------------------------------------

const Logo = forwardRef(
  ({ disabledLink = false, sx, isFull, ...other }, ref) => {
    const theme = useTheme();
    const { mtoConfig } = useSelector((state) => state.config);

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    console.log(mtoConfig.logo);
    // -------------------------------------------------------
    const logo = (
      <Box
        component="img"
        src={mtoConfig?.logo}
        sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
      />
    );

    // const logo = (
    //   <Box
    //     ref={ref}
    //     component='div'
    //     sx={{
    //       width: 40,
    //       height: 40,
    //       display: "inline-flex",
    //       ...sx,
    //     }}
    //     {...other}
    //   >
    //     <img src='assets/logo/logo_.png' />
    //   </Box>
    // );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
