import PropTypes from 'prop-types';
//
import Image from '../../image';
import DocumentIcon from '../../../assets/icons/DocumentIcon.svg';

// ----------------------------------------------------------------------

SingleFilePreview.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default function SingleFilePreview({ file }) {
  if (!file) {
    return null;
  }
  console.log(file);

  const type = file.type.split('/')[0];

  let imgUrl;

  if (typeof file === 'string') {
    imgUrl = file;
  } else {
    console.log(type);
    if (type === 'image') {
      imgUrl = file.preview;
    } else {
      imgUrl = DocumentIcon;
    }
  }

  return (
    <Image
      alt="file preview"
      src={imgUrl}
      sx={{
        top: 8,
        left: 8,
        zIndex: 8,
        borderRadius: 1,
        position: 'absolute',
        width: 'calc(100% - 16px)',
        height: 'calc(100% - 16px)',
      }}
    />
  );
}
