import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { stbWithoutAuth } from '../../utils/axios';
import { API_ENDPOINTS } from 'src/api';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  countries: [],
  provinces: [],
  cities: [],
  purpose: [],
  senderDocuments: [],
  documentTypes: [],
  coins: [],
  balance: {},
  financialInstruments: [],
  mtoConfig: {
    colors: {
      name: 'orange',
      lighter: '#FEF4D4',
      light: '#FED680',
      // main: '#FEC00E',
      main: '#fda92d',
      dark: '#B66816',
      darker: '#793908',
      contrastText: '#212B36',
    },
    favicon: 'https://media.dev.eduley.com/media/letter-d_Ibq4dLP.png',
    // favicon: null,
    logo: 'https://media.dev.eduley.com/media/letter',
    terms_and_condition_link: 'https://twitter.com',
    privacy_policy: 'https://sdfsdfs',
    mto: {
      name: 'mto name',
      country: { id: 13, name: 'Australia', code2: 'AU', phone: 61 },
    },
    tagline: 'Mto Tagline',
  },
};

const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET KYCLEVELS
    getCountriesSuccess(state, action) {
      state.isLoading = false;
      state.countries = action.payload;
    },

    getProvinceSuccess(state, action) {
      state.isLoading = false;
      state.provinces = action.payload;
    },

    getCitySuccess(state, action) {
      state.isLoading = false;
      state.cities = action.payload;
    },

    getPurposeSuccess(state, action) {
      state.isLoading = false;
      state.purpose = action.payload;
    },
    getSenderDocumentSuccess(state, action) {
      state.isLoading = false;
      state.senderDocuments = action.payload;
    },
    getDocumentTypeSuccess(state, action) {
      state.isLoading = false;
      state.documentTypes = action.payload;
    },

    getCoinsSuccess(state, action) {
      state.isLoading = false;
      state.coins = action.payload;
    },
    getBalanceSuccess(state, action) {
      state.isLoading = false;
      state.balance = action.payload;
    },

    getFinancialInstrumentsSuccess(state, action) {
      state.isLoading = false;
      state.financialInstruments = action.payload;
    },
    getMtoConfigSuccess(state, action) {
      state.isLoading = false;
      state.mtoConfig = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getMtoConfigData() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const domainName = window.location.hostname;
      // if
      const response = await stbWithoutAuth.get(
        `${process.env.REACT_APP_CLOUDFRONT_URL}media/mto_theme/${domainName}.json`
      );
      // console.log(response);

      dispatch(slice.actions.getMtoConfigSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCountries() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(API_ENDPOINTS.countries);
      dispatch(slice.actions.getCountriesSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProvince(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(API_ENDPOINTS.province, {
        params: { country: id },
      });
      dispatch(slice.actions.getProvinceSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCity(countryId, provinceId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(API_ENDPOINTS.city, {
        params: { country: countryId, region: provinceId },
      });
      dispatch(slice.actions.getCitySuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPurpose() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(API_ENDPOINTS.purpose);
      dispatch(slice.actions.getPurposeSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getDocumentTypes() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(API_ENDPOINTS.documentType);
      dispatch(slice.actions.getDocumentTypeSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSenderDocument() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(API_ENDPOINTS.senderDocument);
      dispatch(slice.actions.getSenderDocumentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCoins() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(API_ENDPOINTS.coin);
      dispatch(slice.actions.getCoinsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function uploadSenderDocument(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    let token = localStorage.getItem('token');
    const config = {
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        API_ENDPOINTS.senderDocument,
        data,
        config
      );
      dispatch(getSenderDocument());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteSenderDocument(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(
        `${API_ENDPOINTS.senderDocument}${id}/`
      );
      dispatch(getSenderDocument());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCountryCurrency(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const params = {
      country: id,
    };
    try {
      const response = await axios.get(API_ENDPOINTS.currency, {
        params,
      });
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBalance() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(API_ENDPOINTS.balance);
      dispatch(
        slice.actions.getBalanceSuccess(Number(response.data.data.balance))
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFinancialInstruments() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(API_ENDPOINTS.financialInstruments);
      dispatch(
        slice.actions.getFinancialInstrumentsSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
