// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  users: icon('ic_users'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  kycLevel: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  statement: icon('ic_statement'),
  deposit: icon('ic_deposit'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
      },
      {
        title: 'remittance',
        path: PATH_DASHBOARD.remittance.root,
        icon: ICONS.invoice,
      },
      {
        title: 'statement',
        path: PATH_DASHBOARD.statement.root,
        icon: ICONS.statement,
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: 'deposits',
        path: PATH_DASHBOARD.deposit.root,
        icon: ICONS.deposit,
        // children: [
        // { title: 'list', path: PATH_DASHBOARD.invoice.list },
        // { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
        // { title: 'create', path: PATH_DASHBOARD.invoice.new },
        // { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
        // ],
      },
      {
        title: 'receivers',
        path: PATH_DASHBOARD.receivers.root,
        icon: ICONS.users,
        children: [
          {
            title: 'individual',
            path: PATH_DASHBOARD.receivers.individualList,
          },
          {
            title: 'business',
            path: PATH_DASHBOARD.receivers.businessList,
          },
          // { title: 'business', path: PATH_DASHBOARD.invoice.demoView },
          // { title: 'create', path: PATH_DASHBOARD.invoice.new },
          // { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
        ],
      },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'KYC',
    items: [
      {
        title: 'KYC Levels',
        path: PATH_DASHBOARD.kyc.levels,
        icon: ICONS.kycLevel,
      },
    ],
  },
  {
    subheader: 'Profile',
    items: [
      {
        title: 'User Account',
        path: PATH_DASHBOARD.user.account,
        icon: ICONS.user,
      },
    ],
  },
  {
    subheader: 'support',
    items: [
      {
        title: 'chat',
        path: PATH_DASHBOARD.chat.root,
        icon: ICONS.chat,
      },
    ],
  },
];

export default navConfig;
