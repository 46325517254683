export const API_ENDPOINTS = {
  countries: '/country/',
  city: '/city/',
  province: '/province/',
  changePassword: '/mto/user/change_password/',
  login: '/customer/login/',
  senderRegister: '/customer/register/',
  profile: '/customer/profile/',
  deposits: '/sender/fund/deposit/',
  depositStatusOptions: '/mto/deposits/depositstatusoption/',
  timezones: '/timezones/',

  // Statement
  statement: '/customer/wallet/transactions/',

  // Dashboard
  depositDetails: '/customer/dashboard/deposit_details/',
  kycLimitDetails: '/customer/dashboard/kyc_details/',

  // Support
  categories: '/usersupport/category/',
  ticket: '/usersupport/user/ticket/',
  ticketReply: '/usersupport/user/reply/',

  // kyc
  kycLevels: '/kyc/kyc_level/',
  userKyc: '/kyc/user_kyc/',
  senderDocument: '/sender/document/',
  documentType: '/kyc/document_type/',

  //Config
  purpose: '/remittance/puposes/',
  coin: '/coin/',
  currency: '/currency/',
  balance: '/customer/wallet/balance/',
  financialInstruments: '/financial/instruments/',

  // remittance
  receiversIndividual: '/customer/receiver/individual/',
  receiversBusiness: '/customer/receiver/business/',
  selectMto: '/remittance/auto_select_mto/',
  calculateFee: '/remittance/calculate_fee/',
  remittance: '/customer/remittance/',
  financial: '/financial/',

  forgotPassword: '/mto/user/reset_password/',
  updatePassword: '/mto/user/update_password/',
  newPassword: '/mto/user/new_password/',
};
