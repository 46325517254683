import axios from 'axios';
// config
import { HOST_BASE_URL } from '../config-global';

// ----------------------------------------------------------------------

console.log(process.env.REACT_APP_HOST_BASE_UR, HOST_BASE_URL);
const stbServer = axios.create({
  baseURL: HOST_BASE_URL,
});

export const stbWithoutAuth = axios.create({
  baseURL: HOST_BASE_URL,
});

stbServer.interceptors.request.use(
  async function (options) {
    if (localStorage.getItem('accessToken')) {
      options.headers['Authorization'] = `Bearer ${localStorage.getItem(
        'accessToken'
      )}`;
    }
    // options.headers['Content-Type'] = 'application/json';

    return options;
  },
  function (error) {
    return Promise.reject(error);
  }
);

stbServer.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log('Error: ', error.response);
    return Promise.reject(
      (error.response && error.response.data) || error || 'Something went wrong'
    );
  }
);

export default stbServer;
