import React from 'react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// @mui
import { Box, Typography, Tooltip, Stack } from '@mui/material';
//

import { useTheme } from '@mui/material/styles';
import getRatio from './getRatio';
import Iconify from '../iconify';

// ----------------------------------------------------------------------

const Image = forwardRef(
  (
    {
      ratio,
      title = null,
      disabledEffect = false,
      effect = 'blur',
      sx,
      isSelected = false,
      onClick,
      objectFit = 'contain',
      ...other
    },
    ref
  ) => {
    const theme = useTheme();

    const content = (
      <>
        <Box
          component={LazyLoadImage}
          wrapperClassName="wrapper"
          effect={disabledEffect ? undefined : effect}
          placeholderSrc={
            disabledEffect
              ? '/assets/transparent.png'
              : '/assets/placeholder.svg'
          }
          sx={{ width: 1, height: 1, objectFit, ...sx }}
          {...other}
        />
        {title && (
          <Typography
            variant="body2"
            sx={{
              position: 'absolute',
              bottom: '0',
              background: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              width: '100%',
              textAlign: 'center',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              padding: '5px',
            }}
          >
            {title}
          </Typography>
        )}

        {isSelected && (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              borderRadius: '50%',
              bgcolor: 'white',
            }}
          >
            <Iconify
              icon={'material-symbols:check-circle-rounded'}
              sx={{
                height: '24px',
                width: '24px',
                color: `${theme.palette.success.main}`,
              }}
            />
          </Stack>
        )}
      </>
    );

    if (ratio) {
      return (
        <Tooltip title={title} arrow>
          <Box
            onClick={onClick}
            ref={ref}
            component="span"
            sx={{
              width: 1,
              lineHeight: 1,
              display: 'block',
              overflow: 'hidden',
              position: 'relative',
              pt: getRatio(ratio),
              '& .wrapper': {
                top: 0,
                left: 0,
                width: 1,
                height: 1,
                position: 'absolute',
              },
              ...sx,
              border: isSelected && `2px solid ${theme.palette.success.main}`,
              boxShadow:
                isSelected && `${theme.palette.success.main} 0px 2px 12px`,
            }}
          >
            {content}
          </Box>
        </Tooltip>
      );
    }

    return (
      <Tooltip title={title}>
        <Box
          onClick={onClick}
          ref={ref}
          component="span"
          sx={{
            lineHeight: 1,
            display: 'block',
            overflow: 'hidden',
            position: 'relative',
            '& .wrapper': {
              width: 1,
              height: 1,
              backgroundSize: 'cover',
            },
            ...sx,
            border: isSelected && `2px solid ${theme.palette.success.main}`,
            boxShadow:
              isSelected && `${theme.palette.success.main} 0px 2px 12px`,
          }}
        >
          {content}
        </Box>
      </Tooltip>
    );
  }
);

Image.propTypes = {
  sx: PropTypes.object,
  effect: PropTypes.string,
  disabledEffect: PropTypes.bool,
  ratio: PropTypes.oneOf([
    '4/3',
    '3/4',
    '6/4',
    '4/6',
    '16/9',
    '9/16',
    '21/9',
    '9/21',
    '1/1',
  ]),
};

export default Image;
