import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  IndividualReceiverListPage,
  BusinessReceiverListPage,
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  UpdatePasswordPage,
  ResetPasswordPage,
  DashboardPage,
  DepositListPage,
  DepositMethodInfoPage,
  DepositCreatePage,
  DepositStatusPage,
  KycLevelsPage,
  RemittancePage,
  RemittanceListPage,
  ChatPage,
  Page500,
  Page403,
  Page404,
  HomePage,
  MaintenancePage,
  SignupPage,
  IndividualReceiverCreatePage,
  IndividualReceiverEditPage,
  BusinessReceiverCreatePage,
  BusinessReceiverEditPage,
  StatementListPage,
  UserAccountPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'signup',
          element: (
            <GuestGuard>
              <SignupPage />
            </GuestGuard>
          ),
        },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },

            { path: 'update-password', element: <UpdatePasswordPage /> },

            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <DashboardPage /> },
        // { path: 'ecommerce', element: <GeneralEcommercePage /> },
        // { path: 'analytics', element: <GeneralAnalyticsPage /> },
        // { path: 'banking', element: <GeneralBankingPage /> },
        // { path: 'booking', element: <GeneralBookingPage /> },
        // { path: 'file', element: <GeneralFilePage /> },
        // {
        //   path: 'e-commerce',
        //   children: [
        //     {
        //       element: <Navigate to="/dashboard/e-commerce/shop" replace />,
        //       index: true,
        //     },
        //     { path: 'shop', element: <EcommerceShopPage /> },
        //     { path: 'product/:name', element: <EcommerceProductDetailsPage /> },
        //     { path: 'list', element: <EcommerceProductListPage /> },
        //     { path: 'product/new', element: <EcommerceProductCreatePage /> },
        //     {
        //       path: 'product/:name/edit',
        //       element: <EcommerceProductEditPage />,
        //     },
        //     { path: 'checkout', element: <EcommerceCheckoutPage /> },
        //   ],
        // },

        {
          path: 'user',
          children: [
            {
              element: <Navigate to="/dashboard/user/account" replace />,
              index: true,
            },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
        {
          path: 'deposit',
          children: [
            {
              element: <Navigate to="/dashboard/deposit/list" replace />,
              index: true,
            },
            { path: 'list', element: <DepositListPage /> },
            { path: 'info', element: <DepositMethodInfoPage /> },
            { path: 'new', element: <DepositCreatePage /> },
            {
              path: ':depositId',
              element: <DepositStatusPage />,
            },
          ],
        },
        {
          path: 'receivers',
          children: [
            {
              element: <Navigate to="/receivers/individual/list" replace />,
              index: true,
            },
            {
              path: 'individual/list',
              element: <IndividualReceiverListPage />,
            },

            {
              path: 'individual/create',
              element: <IndividualReceiverCreatePage />,
            },
            {
              path: 'individual/edit/:userId',
              element: <IndividualReceiverEditPage />,
            },

            {
              path: 'business/edit/:userId',
              element: <BusinessReceiverEditPage />,
            },

            { path: 'business/list', element: <BusinessReceiverListPage /> },
            {
              path: 'business/create',
              element: <BusinessReceiverCreatePage />,
            },
          ],
        },
        {
          path: 'kyc',
          children: [
            {
              element: <Navigate to="/dashboard/kyc/level" replace />,
              index: true,
            },
            { path: 'level', element: <KycLevelsPage /> },
          ],
        },
        {
          path: 'remittance',
          children: [
            {
              element: <Navigate to="/dashboard/remittance/list" replace />,
              index: true,
            },
            { path: 'list', element: <RemittanceListPage /> },
            { path: 'new', element: <RemittancePage /> },
          ],
        },
        {
          path: 'statement',
          children: [
            {
              element: <Navigate to="/dashboard/statement/list" replace />,
              index: true,
            },
            { path: 'list', element: <StatementListPage /> },
          ],
        },
        // {
        //   path: 'blog',
        //   children: [
        //     {
        //       element: <Navigate to="/dashboard/blog/posts" replace />,
        //       index: true,
        //     },
        //     { path: 'posts', element: <BlogPostsPage /> },
        //     { path: 'post/:title', element: <BlogPostPage /> },
        //     { path: 'new', element: <BlogNewPostPage /> },
        //   ],
        // },
        // { path: 'files-manager', element: <FileManagerPage /> },
        // {
        //   path: 'mail',
        //   children: [
        //     {
        //       element: <Navigate to="/dashboard/mail/all" replace />,
        //       index: true,
        //     },
        //     { path: 'label/:customLabel', element: <MailPage /> },
        //     { path: 'label/:customLabel/:mailId', element: <MailPage /> },
        //     { path: ':systemLabel', element: <MailPage /> },
        //     { path: ':systemLabel/:mailId', element: <MailPage /> },
        //   ],
        // },
        {
          path: 'chat',
          children: [
            { element: <ChatPage />, index: true },
            { path: 'new', element: <ChatPage /> },
            { path: ':conversationKey', element: <ChatPage /> },
          ],
        },
        // { path: 'calendar', element: <CalendarPage /> },
        // { path: 'kanban', element: <KanbanPage /> },
        // { path: 'permission-denied', element: <PermissionDeniedPage /> },
        // { path: 'blank', element: <BlankPage /> },
      ],
    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        {
          element: (
            <GuestGuard>
              <HomePage />
            </GuestGuard>
          ),
          index: true,
        },
        // { path: 'about-us', element: <AboutPage /> },
        // { path: 'contact-us', element: <Contact /> },
        // { path: 'faqs', element: <FaqsPage /> },
        // Demo Components
      ],
    },
    // {
    //   element: <SimpleLayout />,
    //   children: [
    //     { path: 'pricing', element: <PricingPage /> },
    //     { path: 'payment', element: <PaymentPage /> },
    //   ],
    // },
    {
      element: <CompactLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
