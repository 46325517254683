import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { Stack, TextField, Tooltip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import Iconify from "../iconify/Iconify";
import moment from "moment";

// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
};

export default function RHFDatePicker({ name, helperText, tooltip, ...other }) {
  const { control } = useFormContext();

  return (
    <Stack direction='row' alignItems='center' spacing={1}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            {...other}
            value={field.value}
            // inputFormat='dd-MM-yyyy'
            onChange={(newValue) => {
              field.onChange(moment(newValue).format("YYYY-MM-DD"));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                // InputLabelProps={{ style: { fontStyle: 'italic' } }}
                fullWidth
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        )}
      />

      {tooltip && (
        <Tooltip title={tooltip}>
          <Iconify icon='eva:info-outline' width={16} sx={{ mx: 0.5 }} />
        </Tooltip>
      )}
    </Stack>
  );
}
