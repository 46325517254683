import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { API_ENDPOINTS } from 'src/api';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  kycLevels: [],
};

const slice = createSlice({
  name: 'kyc',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET KYCLEVELS
    getKycLevelsSuccess(state, action) {
      state.isLoading = false;
      state.kycLevels = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getKycLevels() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(API_ENDPOINTS.kycLevels);
      console.log(response);
      dispatch(slice.actions.getKycLevelsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpgradeTicket(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(API_ENDPOINTS.ticket, data);
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      throw new Error(error?.message ?? 'Failed');
    }
  };
}
